<template>
  <PageWithLayout :isBodyFull="true">
    <div class="group_category">
      <GroupCategory
        :groupCategoryData.sync="viewModel.groupCategoryDrawData.CATEGORY"
        :dataList.sync="viewModel.dataViewModel.CATEGORY.dataList"
        :value.sync="viewModel.dataViewModel.CATEGORY.value"
        rowIdValue="uid"
        @onClickAdd="(categoryId) => viewModel.onClickAdd(categoryId)"
        @onClickCategoryItem="(categoryId, rowData) => viewModel.onSelectCategoryItem(categoryId, rowData)"
        @onModifyOrder="orderData => viewModel.onModifyOrder(orderData)"
      >
        <template v-slot:item_category="slotProps">
          <span class="txt_item">
            <IconSvg iconName="drag" :size="18" iconColor="#bbb" />
          </span>
          <span class="txt_item">
            <span
              class="box_color"
              :style="
                `background-color:${$options.filters.convertIdToColor(
                  slotProps.itemData.status,
                  'barcode_status_hex',
                )}`
              "
            ></span>
          </span>
          <span
            class="txt_item img_item"
            :style="`background-color:${slotProps.itemData.color_code}`"
          >
            <img
              class="img_thumnail"
              :src="slotProps.itemData.icon_img_url"
              alt=""
            />
          </span>
          <strong class="tit_item" style="width:80px;margin:0 14px 0 12px;">{{
            slotProps.itemData.category_name
          }}</strong>
          <span class="tit_item fill_item">
            {{ slotProps.itemData.category_description }}
          </span>
          <!-- <span
            class="txt_item"
            :style="
              `background-color:${$options.filters.convertIdToColor(
                slotProps.itemData.color,
                'barcode_category_color_text',
              )}`
            "
            >{{
              slotProps.itemData.color
                | convertIdToText('barcode_category_color_text')
            }}</span
          > -->
        </template>
      </GroupCategory>
      <GroupCategory
        :groupCategoryData.sync="viewModel.groupCategoryDrawData.BRAND"
        :dataList.sync="viewModel.dataViewModel.BRAND.dataList"
        :value.sync="viewModel.dataViewModel.BRAND.value"
        rowIdValue="brand_code"
        :upperData="{
          title: viewModel.groupCategoryDrawData.CATEGORY.title,
          value: viewModel.dataViewModel.CATEGORY.value,
        }"
        @onClickAdd="(categoryId) => viewModel.onClickAdd(categoryId)"
        @onClickCategoryItem="(categoryId, rowData) => viewModel.onSelectCategoryItem(categoryId, rowData)"
        @onModifyOrder="orderData => viewModel.onModifyOrder(orderData)"
      >
        <template v-slot:item_category="slotProps">
          <span class="txt_item">
            <IconSvg iconName="drag" :size="18" iconColor="#bbb" />
          </span>
          <span class="txt_item">
            <span
              class="box_color"
              :style="
                `background-color:${$options.filters.convertIdToColor(
                  slotProps.itemData.status,
                  'barcode_status_hex',
                )}`
              "
            ></span>
          </span>
          <strong class="txt_count">
            <img
              class="img_thumnail"
              :src="slotProps.itemData.brand_logo_img_url"
              alt=""
            />
          </strong>
          <strong class="tit_item fill_item">{{
            slotProps.itemData.brand_name
          }}</strong>
          <span class="txt_item">{{
            slotProps.itemData.payment_category
              | convertIdToText('barcode_payment_category')
          }}</span>
          <span class="txt_item"
            >{{ slotProps.itemData.payback_percent }}%</span
          >
        </template>
      </GroupCategory>
    </div>
    <template v-slot:popup>
      <CategoryRegisterPopup
        v-if="viewModel.groupCategoryDrawData.CATEGORY.isRegisterPopup"
        :mappingViewModel="viewModel"
        @onClickPopupClose="viewModel.onClickPopupClose('CATEGORY')"
        @onClickPopupComplete="
          data => viewModel.onClickPopupComplete('CATEGORY', data)
        "
      />
      <BrandRegisterPopup
        v-if="viewModel.groupCategoryDrawData.BRAND.isRegisterPopup"
        :mappingViewModel="viewModel"
        @onClickPopupClose="viewModel.onClickPopupClose('BRAND')"
        @onClickPopupComplete="
          data => viewModel.onClickPopupComplete('BRAND', data)
        "
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import GroupCategory from '@lemontree-ai/lemontree-admin-common-front/components/common/category/GroupCategory';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
// popup
import CategoryRegisterPopup from '@/views/service/barcode/mapping/view/popup/CategoryRegisterPopup';
import BrandRegisterPopup from '@/views/service/barcode/mapping/view/popup/BrandRegisterPopup';

// viewModel
import BarcodeMappingListViewModel from '@/views/service/barcode/mapping/viewModel/BarcodeMappingListViewModel';

export default {
  name: 'BarcodeBrandMapping',
  components: {
    PageWithLayout,
    GroupCategory,
    IconSvg,

    CategoryRegisterPopup,
    BrandRegisterPopup,
  },
  data() {
    return {
      viewModel: new BarcodeMappingListViewModel(),
    };
  },
  beforeMount() {
    this.viewModel.init();
  }
};
</script>

<style scoped>
.group_category {
  flex: 1 1 100%;
  height: calc(100% + 20px);
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.group_category .area_category:nth-child(1) {
  width: 40%;
}
.group_category .area_category:nth-child(2) {
  width: 60%;
}
.img_item{
  width:32px;
  height:32px;
  margin:-5px 0 -6px;
  padding:2px;
  border-radius:6px;
  box-sizing: border-box;
}
.img_thumnail {
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 4px;
}
.layer_dimmed{
  display:flex;
  align-items:center;
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color:rgba(0,0,0,0.4);
  color:#fff;
}
.layer_dimmed p{
  flex:1 0;
  padding-left:200px;
  padding-bottom:100px;
  text-align:center;
  font-size:24px;
}
</style>
