export default class BarcodeCategoryModel {
  constructor() {
    this.objRaw = {};

    this.uid = '';
    this.category_seq = 0;
    this.category_name = '';
    this.category_description = '';
    this.color = '';
    this.color_code = '';
    this.icon_img_url = '';
    this.icon_img_list = [];
    this.status = '';
  }
  setData(obj) {
    if (obj) this.objRaw = obj;
    const {
      uid,
      category_seq,
      category_name,
      category_description,
      color,
      color_code,
      icon_img_url,
      status,
    } = obj;

    this.uid = uid;
    this.category_seq = category_seq;
    this.category_name = category_name;
    this.category_description = category_description;
    this.color = color;
    this.color_code = color_code;
    if(icon_img_url){
      this.icon_img_list = [
        {
          src : icon_img_url,
        }
      ]
    }
    this.icon_img_url = icon_img_url;
    this.status = status;
  }
  setSeqData(seq){
    this.category_seq = seq;
  }
  getData() {
    let obj = {
      category_name: this.category_name,
      category_description: this.category_description,
      color: this.color,
      icon_img_url: this.icon_img_list.length > 0 ? this.icon_img_list[0].src : '',
    };
    return obj;
  }
}
