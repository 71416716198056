<template>
  <Popup
    :title="isModify ? '카테고리 수정' : '새로운 카테고리 추가'"
    :maxWidth="1000"
    closeBtnText="닫기"
    :isDisabeldCompeleteBtn="vaild"
    compeleteBtnText="저장"
    @onClickClose="$emit('onClickPopupClose')"
    @onClickComplete="onClickPopupComplete()">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:160px"/>
        <col />
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>color<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
              <Selectbox
                :dataList="'barcode_category_color_text' | getSelectDataList"
                :value.sync="dataViewModel.color"
                maxWidth="300px"/>
          </td>
        </tr>
        <tr>
          <th>아이콘<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <FileUpload
              :dataList.sync="dataViewModel.icon_img_list"
              :canMultiple="false"
              :acceptList="['svg']"
              :saveTrigger="saveTrigger"
              :isSaved.sync="isSaved"/>
          </td>
        </tr>
        <tr>
          <th>카테고리명<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              placeholder="카테고리명을 입력해주세요."
              :value.sync="dataViewModel.category_name"/>
          </td>
        </tr>
        <tr>
          <th>카테고리 타이틀<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              placeholder="카테고리 타이틀을 입력해주세요."
              :value.sync="dataViewModel.category_description"/>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import FileUpload from '@lemontree-ai/lemontree-admin-common-front/components/common/file/FileUpload';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils';
import CategoryModel from '@/views/service/barcode/mapping/model/CategoryModel';
// mixin
import FileSaveMixin from '@lemontree-ai/lemontree-admin-common-front/mixins/FileSaveMixin'

export default {
  name:'CategoryRegisterPopup',
  mixins:[FileSaveMixin],
  components:{
    Popup,
    TableView,
    IconSvg,
    Selectbox,
    Input,
    FileUpload,
  },
  props:{
    mappingViewModel: Object,
  },
  data(){
    return{
      dataViewModel: new CategoryModel(),
    }
  },
  computed:{
    isModify(){
      return this.mappingViewModel.dataViewModel.CATEGORY.detailData.uid;
    },
    vaild(){
      const validData = this.mappingViewModel.dataViewModel.CATEGORY.validData
      return pageValidated(validData, this.dataViewModel);
    },
  },
  beforeMount(){
    if(this.isModify){
      this.dataViewModel.setData(this.mappingViewModel.dataViewModel.CATEGORY.detailData);
    }
  },
  methods:{
    onClickPopupComplete(){
      if(this.dataViewModel.icon_img_list.length > 0 && this.dataViewModel.icon_img_list[0].file){
        this.onSave();
      }else{
        this.onCompleteFileSave();
      }
    },
    onCompleteFileSave(){
      this.$emit('onClickPopupComplete',this.dataViewModel.getData())
    }
  }
}
</script>