export default class MapperModel {
  constructor() {
    this.objRaw = {};


    this.brand_info_uid = '';
    this.brand_seq = '';
    this.category_uid = '';
    this.status = '';
  }
  setData(obj) {
    if (obj) this.objRaw = obj;
    const {
      brand_info_uid,
      brand_seq,
      category_uid,
      status
    } = obj;
    

    this.brand_info_uid = brand_info_uid;
    this.brand_seq = brand_seq;
    this.category_uid = category_uid;
    this.status = status;
  }
  getData() {
    let obj = {
      brand_info_uid: this.brand_info_uid,
      brand_seq: this.brand_seq,
      category_uid: this.category_uid,
      status: this.status,
    };
    return obj;
  }
}
